import { ReactNode } from "react";

// material-ui
import { Box, Grid, Stack } from "@mui/material";

// project import
import AuthFooter from "components/cards/AuthFooter";
import Logo from "components/logo";
import AuthCard from "./AuthCard";

// assets
import SiebenAuthBg from "assets/images/auth/SiebenAuthBg.jpeg";
import styled from "@emotion/styled";
// import Localization from "layout/MainLayout/Header/HeaderContent/Localization";

interface Props {
  children: ReactNode;
}

// ==============================|| AUTHENTICATION - WRAPPER ||============================== //

const AuthWrapperStyle = styled("div")(() => ({
  background: `url(${SiebenAuthBg}) no-repeat center center fixed`,
  backgroundSize: "cover",
}));

const AuthWrapper = ({ children }: Props) => {
  // const matchUpMD = useMediaQuery((theme: Theme) => theme.breakpoints.up("md"));

  return (
    <AuthWrapperStyle>
      <Box sx={{ minHeight: "100vh" }}>
        <Grid
          container
          direction="column"
          justifyContent="flex-end"
          sx={{
            minHeight: "100vh",
          }}
        >
          <Grid item xs={12} sx={{ ml: 3, mt: 3, mr: 3 }}>
            <Stack
              direction="row"
              alignItems="flex-start"
              justifyContent="space-between"
            >
              <Logo />
              {/*<Localization showText={matchUpMD} />*/}
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Grid
              item
              xs={12}
              container
              justifyContent="center"
              alignItems="center"
              sx={{
                minHeight: {
                  xs: "calc(100vh - 210px)",
                  sm: "calc(100vh - 134px)",
                  md: "calc(100vh - 112px)",
                },
              }}
            >
              <Grid item>
                <AuthCard>{children}</AuthCard>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sx={{ m: 3, mt: 1 }}>
            <AuthFooter />
          </Grid>
        </Grid>
      </Box>
    </AuthWrapperStyle>
  );
};

export default AuthWrapper;
