import axios, { AxiosInstance } from "axios";
import { AWSCognitoContextType } from "types/auth";

const AxiosServices = (auth: AWSCognitoContextType): AxiosInstance => {
  const service = axios.create({
    baseURL: process.env.REACT_APP_API_ENDPOINT,
    headers: {
      "Content-Type": "application/json",
    },
  });

  service.interceptors.request.use(async (config) => {
    const session = await auth.getSession();
    const token = session.getAccessToken().getJwtToken();
    if (token) {
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${token}`,
      };
    }
    return config;
  });

  return service;
};

export default AxiosServices;
