import {
  Jca,
  JcaActionResult,
  JcaActionType,
  JcaStatus,
} from "domain/jca/jca-model";
import {
  JcaActionResultResponse,
  JcaDownloadResponse,
  JcaResponse,
} from "./jca-response-model";

const mapStringToJcaStatus = (status: string): JcaStatus => {
  switch (status) {
    case "NOT_STARTED":
      return JcaStatus.NotStarted;
    case "OFFER_SENT":
      return JcaStatus.OfferSent;
    case "OFFER_RECEIVED":
      return JcaStatus.OfferReceived;
    case "COMPLETED":
      return JcaStatus.Completed;
    case "REJECTED":
      return JcaStatus.Rejected;
    case "NOT_RELEVANT":
      return JcaStatus.NotRelevant;
    default:
      return JcaStatus.Unknown;
  }
};

export const mapJcaActionToString = (action: JcaActionType): string => {
  switch (action) {
    case JcaActionType.SendOffer:
      return "SEND_OFFER";
    case JcaActionType.AcceptOffer:
      return "ACCEPT_OFFER";
    case JcaActionType.RejectOffer:
      return "REJECT_OFFER";
    case JcaActionType.MakeCounterOffer:
      return "SEND_OFFER";
    case JcaActionType.CancelOffer:
      return "CANCEL_OFFER";
    case JcaActionType.MarkAsNotRelevant:
      return "MARK_AS_NOT_RELEVANT";
    case JcaActionType.Reopen:
      return "REOPEN";
    case JcaActionType.Amend:
      return "AMEND";
    default:
      return "NONE";
  }
};

export const mapJcaResponse = (response: JcaResponse): Jca => {
  return {
    username: response.username,
    name: response.name,
    email: response.email,
    address: response.address,
    mediaOffers: response.website,
    status: mapStringToJcaStatus(response.status),
    message: response.message,
    sentBy: response.sent_by,
  };
};

export const mapJcaActionResultResponse = (
  response: JcaActionResultResponse
): JcaActionResult => {
  return {
    newStatus: mapStringToJcaStatus(response.new_status),
  };
};

export const mapJcaDownloadResponse = (
  response: JcaDownloadResponse
): string => {
  return response.url;
};
