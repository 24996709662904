import { lazy } from "react";

// project import
import MainLayout from "layout/MainLayout";
import Loadable from "components/Loadable";
import AuthGuard from "utils/route-guard/AuthGuard";

// render - sample page
const JcaListPage = Loadable(
  lazy(() => import("pages/jca/jca-list/jca-list-page"))
);

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: "/",
  children: [
    {
      path: "/",
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: "jca-list",
          element: <JcaListPage />,
        },
      ],
    },
  ],
};

export default MainRoutes;
