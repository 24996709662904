// material-ui
import { Link } from "@mui/material";
import { Typography } from "@mui/material";

// project import
import AuthWrapper from "sections/auth/AuthWrapper";

// ==============================|| SAMPLE PAGE ||============================== //

const PrivacyPage = () => (
  <AuthWrapper>
    <Typography variant="h2">
      Datenschutzhinweise der Seven.One Entertainment Group GmbH
    </Typography>
    <Typography variant="body1">
      <p>Stand: 13.12.2022</p>
      <p>
        Die nachfolgenden Datenschutzhinweise gelten f&uuml;r die Website
        jca-generator.seven.one.
        <br />
        <br />
      </p>
      <p>
        <Typography variant="h4">
          1. Wer sind wir? (Verantwortlicher)
        </Typography>
      </p>
      <p>Verantwortlicher im datenschutzrechtlichen Sinn ist die </p>
      <p>
        Seven.One Entertainment Group GmbH
        <br />
        Medienallee 7<br />
        85774 Unterf&ouml;hring
      </p>
      <p>
        <br />
        Mail:&nbsp;
        <Link href="mailto:jca-generator@seven.one" color="text.primary">
          jca-generator@seven.one
        </Link>
        <br />
        URL:&nbsp;jca-generator.seven.one
      </p>
      <p>
        nachfolgend &ldquo;wir&rdquo; bzw. &ldquo;uns&rdquo;.
        <br />
        Ausnahmen werden in diesen Datenschutzhinweise erl&auml;utert.
      </p>
      <Typography variant="h4">
        2. Kontaktdaten unseres Datenschutzbeauftragten
      </Typography>
      <p>
        Sollten Sie Fragen zu diesen Datenschutzhinweisen oder generell zur
        Verarbeitung Ihrer Daten im Rahmen dieses Online-Angebots haben, wenden
        Sie sich bitte an unseren Datenschutzbeauftragten:
        <br />
        Nutzen Sie hierf&uuml;r einfach die Angaben in Abschnitt Kontakt.
      </p>
      <Typography variant="h4">
        3. Wof&uuml;r verarbeiten wir Ihre personenbezogenen Daten und auf
        welcher Rechtsgrundlage?
      </Typography>
      <p>
        Personenbezogene Daten sind alle Informationen, die sich auf eine
        identifizierte oder identifizierbare nat&uuml;rliche Person beziehen.
        Wenn wir personenbezogene Daten verarbeiten bedeutet dies, dass wir
        diese z. B. erheben, speichern, verwenden, an andere &uuml;bermitteln
        oder l&ouml;schen.
      </p>
      <p>
        Wir verarbeiten Ihre personenbezogenen Daten zu nachfolgenden Zwecken
        und auf Basis der genannten Rechtsgrundlagen. F&uuml;r den Fall, dass
        die Datenverarbeitung auf der Rechtsgrundlage Berechtigtes Interesse
        beruht, erl&auml;utern wir Ihnen auch unser berechtigtes Interesse, das
        wir mit der Verarbeitung verfolgen.
      </p>
      <Typography variant="h5">Allgemeine Zwecke der Verarbeitung:</Typography>
      <p>
        Wir verarbeiten Ihre personenbezogenen Daten zu nachfolgenden Zwecken:
      </p>
      <p>
        1. Bereitstellung dieses Online-Angebots. Dies beinhaltet insbesondere:
      </p>
      <ul>
        <li>Erm&ouml;glichung der Nutzung des Online-Angebots;</li>
        <li>
          Erm&ouml;glichung der Nutzung des Login-Bereichs des Online-Angebots
          f&uuml;r registrierte Nutzer:innen;
        </li>
        <li>
          Bereitstellung einer Kontaktm&ouml;glichkeit sowie Beantwortung
          dar&uuml;ber gesendeter Anfragen;
        </li>
      </ul>
      <p>Rechtsgrundlage: Vertragserf&uuml;llung</p>
      <p>
        2. Einwilligungs- und Opt-out-Management gem&auml;&szlig;
        Datenschutzrecht
        <br />
        Rechtsgrundlage: Erf&uuml;llung einer gesetzlichen Verpflichtung
      </p>
      <p>
        3. Informationssicherheit: Ermittlung von St&ouml;rungen und
        Gew&auml;hrleistung der Systemsicherheit einschlie&szlig;lich Aufdeckung
        und Nachverfolgung unzul&auml;ssiger Zugriffe und Zugriffsversuche auf
        unsere Web-Server.
        <br />
        Rechtsgrundlage: Erf&uuml;llung unserer rechtlichen Verpflichtungen zur
        Einhaltung der Datensicherheit sowie berechtigtes Interesse ( an der
        Beseitigung von St&ouml;rungen, der Gew&auml;hrleistung der
        Systemsicherheit und der Aufdeckung und Nachverfolgung unzul&auml;ssiger
        Zugriffsversuche bzw. Zugriffe
      </p>
      <p>
        4. Einhaltung gesetzlicher Aufbewahrungspflichten und sonstiger
        rechtlicher Verpflichtungen (z. B. im Zusammenhang mit
        Betriebspr&uuml;fungen).
        <br />
        Rechtsgrundlage: Erf&uuml;llung unserer rechtlichen Verpflichtungen,
        insbesondere in Bezug auf die Aufbewahrung bestimmter Informationen und
        im Zusammenhang mit Betriebspr&uuml;fungen; berechtigtes Interesse (an
        der Schaffung der Voraussetzungen f&uuml;r die Einhaltung rechtlicher
        Verpflichtungen)
      </p>
      <p>
        5. Wahrung und Verteidigung unserer Rechte.
        <br />
        Rechtgrundlage: berechtigtes Interesse (an der Geltendmachung und
        Verteidigung unserer Rechte)
      </p>
      <Typography variant="h4">
        4. Wer bekommt Ihre personenbezogenen Daten und warum?
      </Typography>
      <br />
      <Typography variant="h5">Weitergabe von Daten an Dritte</Typography>
      <p>
        Ihre personenbezogenen Daten werden von uns grunds&auml;tzlich nur dann
        an Dritte gegeben, soweit dies zur Vertragserf&uuml;llung erforderlich
        ist, wir oder der Dritte ein berechtigtes Interesse an der Weitergabe
        haben, Ihre Einwilligung hierf&uuml;r vorliegt oder dies zur
        Erf&uuml;llung einer rechtlichen Verpflichtung erforderlich ist.
        <br />
        Wir k&ouml;nnen personenbezogene Daten gegen&uuml;ber einem Dritten
        insbesondere dann offenlegen
      </p>
      <ul>
        <li>
          wenn wir hierzu aufgrund gesetzlicher Vorgaben oder durch
          vollstreckbare beh&ouml;rdliche oder gerichtliche Anordnung im
          Einzelfall verpflichtet sein sollten;
        </li>
        <li>
          im Zusammenhang mit Rechtsstreitigkeiten (gegen&uuml;ber Gerichten
          oder unseren Anw&auml;lten) oder Betriebspr&uuml;fungen
          (gegen&uuml;ber Wirtschaftspr&uuml;fern);
        </li>
        <li>
          im Zusammenhang mit m&ouml;glichen strafbaren Handlungen an die
          zust&auml;ndigen Ermittlungsbeh&ouml;rden;
        </li>
        <li>
          im Falle eines Verkaufs des Gesch&auml;ftsbetriebs (gegen&uuml;ber dem
          Erwerber).
        </li>
      </ul>
      <p>
        Sofern Daten regelm&auml;&szlig;ig an weitere Dritte &uuml;bermittelt
        werden k&ouml;nnen, wird dies in diesen Datenschutzhinweise
        erl&auml;utert. Bei einer &Uuml;bermittlung auf Basis einer Einwilligung
        kann die Erl&auml;uterung auch bei Einholung der Einwilligung erfolgen.
      </p>
      <Typography variant="h5">
        Weitergabe von Daten an Dienstleister
      </Typography>
      <p>
        Wir behalten uns vor, bei der Erhebung bzw. Verarbeitung von Daten
        Dienstleister einzusetzen. Dienstleister erhalten von uns nur die
        personenbezogenen Daten, die sie f&uuml;r ihre konkrete T&auml;tigkeit
        ben&ouml;tigen. Dienstleister k&ouml;nnen auch damit beauftragt werden,
        Serverkapazit&auml;ten zur Verf&uuml;gung zu stellen. Dienstleister
        werden in der Regel als sogenannte Auftragsverarbeiter eingebunden, die
        personenbezogene Daten der Nutzer:innen dieses Online-Angebots nur nach
        unseren Weisungen verarbeiten d&uuml;rfen.
      </p>
      <p>
        Soweit Dienstleister in diesen Datenschutzhinweise nicht bereits
        namentlich benannt sind, handelt es sich um folgende Kategorien von
        Dienstleistern:
      </p>
      <ul>
        <li>IT-Dienstleister (technischer Support), Deutschland</li>
        <li>IT-Dienstleister (Hosting), Deutschland, Irland</li>
      </ul>
      <Typography variant="h4">
        5. Wann geben wir Daten in L&auml;nder, die nicht zum Europ&auml;ischen
        Wirtschaftsraum geh&ouml;ren?
      </Typography>
      <p>
        Wir geben personenbezogene Daten auch an Dritte bzw.
        Auftragsverarbeiter, die ihren Sitz in Nicht-EWR-L&auml;ndern haben. In
        diesem Fall stellen wir vor der Weitergabe sicher, dass beim
        Empf&auml;nger entweder ein angemessenes Datenschutzniveau besteht (z.
        B. aufgrund einer Angemessenheitsentscheidung der EU Kommission f&uuml;r
        das jeweilige Land gem&auml;&szlig; Art. 45 DSGVO oder die Vereinbarung
        sogenannter EU Standardvertragsklauseln der Europ&auml;ischen Kommission
        mit dem Empf&auml;nger gem&auml;&szlig; Art. 46 DSGVO) bzw. eine
        ausdr&uuml;ckliche Einwilligung unserer Nutzer:innen vorliegt.
      </p>
      <p>
        Sie k&ouml;nnen bei uns eine &Uuml;bersicht &uuml;ber die Empf&auml;nger
        in Drittstaaten und eine Kopie der konkret vereinbarten Regelungen zur
        Sicherstellung des angemessenen Datenschutzniveaus erhalten. Bitte
        nutzen Sie hierf&uuml;r die Angaben im Abschnitt Kontakt.
      </p>
      <Typography variant="h4">
        6. Wie lange speichern wir Ihre Daten?
      </Typography>
      <p>
        Wir speichern Ihre Daten solange, wie dies zur Erbringung unseres
        Online-Angebots und der damit verbundenen Services erforderlich ist bzw.
        wir ein berechtigtes Interesse an der weiteren Speicherung haben. In
        allen anderen F&auml;llen l&ouml;schen wir Ihre personenbezogenen Daten
        mit Ausnahme solcher Daten, die wir zur Erf&uuml;llung gesetzlicher (z.
        B. steuer- oder handelsrechtlicher) Aufbewahrungsfristen weiter
        vorhalten m&uuml;ssen (z. B. Rechnungen).
      </p>
      <p>
        Daten, die einer Aufbewahrungsfrist unterliegen, sperren wir bis zum
        Ablauf der Frist.
      </p>
      <Typography variant="h4">7. Log-Dateien</Typography>
      <p>
        Bei jeder Nutzung des Internet werden von Ihrem Internet-Browser
        automatisch bestimmte Informationen &uuml;bermittelt und von uns in
        sogenannten Log-Dateien gespeichert.
      </p>
      <p>
        Die Log-Dateien werden von uns ausschlie&szlig;lich zur Ermittlung von
        St&ouml;rungen und Sicherheitsgr&uuml;nden (z. B. zur Aufkl&auml;rung
        von Angriffsversuchen) f&uuml;r 7 bis 10 Tage gespeichert und danach
        gel&ouml;scht. Log-Dateien, deren weitere Aufbewahrung zu Beweiszwecken
        erforderlich ist, sind von der L&ouml;schung ausgenommen bis zur
        endg&uuml;ltigen Kl&auml;rung des jeweiligen Vorfalls und k&ouml;nnen im
        Einzelfall an Ermittlungsbeh&ouml;rden weitergegeben werden.
      </p>
      <p>
        In den Log-Dateien werden insbesondere folgende Informationen
        gespeichert:
      </p>
      <ul>
        <li>
          IP-Adresse (Internetprotokoll-Adresse) des Endger&auml;ts, von dem aus
          auf das Online-Angebot zugegriffen wird;
        </li>
        <li>
          Internetadresse der Website, von der aus das Online-Angebot aufgerufen
          wurde (sog. Herkunfts- oder Referrer-URL);
        </li>
        <li>
          Name des Service-Providers, &uuml;ber den der Zugriff auf das
          Online-Angebot erfolgt;
        </li>
        <li>Name der abgerufenen Dateien bzw. Informationen;</li>
        <li>Datum und Uhrzeit sowie Dauer des Abrufs;</li>
        <li>&uuml;bertragene Datenmenge;</li>
        <li>
          Betriebssystem und Informationen zum verwendeten Endger&auml;t; und
        </li>
      </ul>
      <p>
        http-Status-Code (z. B. &ldquo;Anfrage erfolgreich&rdquo; oder
        &ldquo;angeforderte Datei nicht gefunden&rdquo;).
      </p>
      <Typography variant="h4">
        8. Ihre Rechte (Rechte der betroffenen Person)
      </Typography>
      <br />
      <Typography variant="h5">
        Wie k&ouml;nnen Sie Ihre Rechte geltend machen?
      </Typography>
      <p>
        Bitte nutzen Sie zur Geltendmachung Ihrer Rechte die Angaben im
        Abschnitt Kontakt. Bitte stellen Sie dabei sicher, dass uns eine
        eindeutige Identifizierung Ihrer Person m&ouml;glich ist.
      </p>
      <Typography variant="h5">
        Ihre Rechte auf Auskunft und Berichtigung
      </Typography>
      <p>
        Sie k&ouml;nnen verlangen, dass wir Ihnen best&auml;tigen, ob wir Sie
        betreffende personenbezogene Daten verarbeiten und Sie haben ein Recht
        auf Auskunft im Hinblick auf Ihre von uns verarbeiteten Daten. Sollten
        Ihre Daten unrichtig oder unvollst&auml;ndig sein, k&ouml;nnen Sie
        verlangen, dass Ihre Daten berichtigt bzw. vervollst&auml;ndigt werden.
        Wenn wir Ihre Daten an Dritte weitergegeben haben informieren wir diese
        &uuml;ber die Berichtigung, soweit dies gesetzlich vorgeschrieben ist.
      </p>
      <Typography variant="h5">Ihr Recht auf L&ouml;schung</Typography>
      <p>
        Sie k&ouml;nnen, wenn die gesetzlichen Voraussetzungen vorliegen, von
        uns unverz&uuml;gliche L&ouml;schung Ihrer personenbezogenen Daten
        verlangen. Dies ist insbesondere der Fall, wenn
      </p>
      <ul>
        <li>
          Ihre personenbezogenen Daten f&uuml;r die Zwecke, f&uuml;r die sie
          erhoben wurden, nicht l&auml;nger ben&ouml;tigt werden;
        </li>
        <li>
          die Rechtsgrundlage f&uuml;r die Verarbeitung ausschlie&szlig;lich
          Ihre Einwilligung war und Sie diese widerrufen haben;
        </li>
        <li>
          Sie einer Verarbeitung auf der Basis der Rechtsgrundlage Berechtigtes
          Interesse aus pers&ouml;nlichen Gr&uuml;nden widersprochen haben und
          wir nicht nachweisen k&ouml;nnen, dass es vorrangige berechtigte
          Gr&uuml;nde f&uuml;r eine Verarbeitung gibt;
        </li>
        <li>
          Ihre personenbezogenen Daten unrechtm&auml;&szlig;ig verarbeitet
          wurden; oder
        </li>
        <li>
          Ihre personenbezogenen Daten gel&ouml;scht werden m&uuml;ssen, um
          gesetzlichen Anforderungen zu entsprechen.
        </li>
      </ul>
      <p>
        Wenn wir Ihre Daten an Dritte weitergegeben haben informieren wir diese
        &uuml;ber die L&ouml;schung, soweit dies gesetzlich vorgeschrieben ist.
      </p>
      <p>
        Bitte beachten Sie, dass Ihr L&ouml;schungsrecht Einschr&auml;nkungen
        unterliegt. Zum Beispiel m&uuml;ssen bzw. d&uuml;rfen wir keine Daten
        l&ouml;schen, die wir aufgrund gesetzlicher Aufbewahrungsfristen noch
        weiter vorhalten m&uuml;ssen. Auch Daten, die wir zur Geltendmachung,
        Aus&uuml;bung oder Verteidigung von Rechtsanspr&uuml;chen
        ben&ouml;tigen, sind von Ihrem L&ouml;schungsrecht ausgenommen.
      </p>
      <Typography variant="h5">
        Ihr Recht auf Einschr&auml;nkung der Verarbeitung
      </Typography>
      <p>
        Sie k&ouml;nnen, wenn die gesetzlichen Voraussetzungen vorliegen, von
        uns eine Einschr&auml;nkung der Verarbeitung verlangen. Dies ist
        insbesondere der Fall, wenn
      </p>
      <ul>
        <li>
          die Richtigkeit Ihrer personenbezogenen Daten von Ihnen bestritten
          wird, und dann solange bis wir die M&ouml;glichkeit hatten, die
          Richtigkeit zu &uuml;berpr&uuml;fen;
        </li>
        <li>
          die Verarbeitung nicht rechtm&auml;&szlig;ig erfolgt und Sie statt der
          L&ouml;schung (siehe hierzu den vorigen Abschnitt) eine
          Einschr&auml;nkung der Nutzung verlangen;
        </li>
        <li>
          wir Ihre Daten nicht mehr f&uuml;r die Zwecke der Verarbeitung
          ben&ouml;tigen, Sie diese jedoch zur Geltendmachung, Aus&uuml;bung
          oder Verteidigung Ihrer Rechtsanspr&uuml;che brauchen;
        </li>
        <li>
          Sie Widerspruch aus pers&ouml;nlichen Gr&uuml;nden erhoben haben, und
          dann solange bis feststeht, ob Ihre Interessen &uuml;berwiegen.
        </li>
      </ul>
      <p>
        Wenn ein Recht auf Einschr&auml;nkung der Verarbeitung besteht,
        markieren wir die betroffenen Daten, um auf diese Weise sicherzustellen,
        dass diese nur noch in den engen Grenzen verarbeitet werden, die
        f&uuml;r solche eingeschr&auml;nkten Daten gelten (n&auml;mlich
        insbesondere zur Verteidigung von Rechtsanspr&uuml;chen oder mit Ihrer
        Einwilligung).
      </p>
      <Typography variant="h5">
        Ihr Recht auf Daten&uuml;bertragbarkeit
      </Typography>
      <p>
        Sie haben das Recht, personenbezogene Daten, die Sie uns zur
        Vertragserf&uuml;llung oder auf Basis einer Einwilligung gegeben haben,
        in einem &uuml;bertragbaren Format zu erhalten. Sie k&ouml;nnen in
        diesem Fall auch verlangen, dass wir diese Daten direkt einem Dritten
        &uuml;bermitteln, soweit dies technisch machbar ist.
      </p>
      <Typography variant="h5">
        Ihr Recht auf Widerruf der Einwilligung
      </Typography>
      <p>
        Sofern Sie uns eine Einwilligung in die Verarbeitung Ihrer Daten erteilt
        haben, k&ouml;nnen Sie diese jederzeit mit Wirkung f&uuml;r die Zukunft
        widerrufen. Die Rechtm&auml;&szlig;igkeit der Verarbeitung Ihrer Daten
        bis zum Widerruf bleibt hiervon unber&uuml;hrt.
      </p>
      <Typography variant="h5">
        Ihr Beschwerderecht bei einer Aufsichtsbeh&ouml;rde
      </Typography>
      <p>
        Sie haben das Recht, eine Beschwerde bei einer Datenschutzbeh&ouml;rde
        einzureichen. Sie k&ouml;nnen sich dazu insbesondere an die
        Datenschutzbeh&ouml;rde wenden, die f&uuml;r Ihren Wohnort bzw. Ihr
        Bundesland zust&auml;ndig ist oder die f&uuml;r den Ort zust&auml;ndig
        ist, an dem die Verletzung des Datenschutzrechts stattgefunden hat.
        Alternativ k&ouml;nnen Sie sich auch an die f&uuml;r uns zust&auml;ndige
        Datenschutzbeh&ouml;rde wenden.
      </p>
      <Typography variant="h4">9. Kontakt</Typography>
      <p>
        F&uuml;r Ausk&uuml;nfte und Anregungen zum Thema Datenschutz stehen wir
        bzw. unser Datenschutzbeauftragter Ihnen gerne zur Verf&uuml;gung.
      </p>
      <p>
        Wenn Sie mit uns in Kontakt treten m&ouml;chten, erreichen Sie uns wie folgt:
      </p>
      <p>
        Seven.One Entertainment Group GmbH
        <br />
        Medienallee 7<br />
        85774 Unterf&ouml;hring
      </p>
      <p>
        Mail:&nbsp;
        <Link href="mailto:jca-generator@seven.one" color="text.primary">
          jca-generator@seven.one
        </Link>
      </p>
    </Typography>
  </AuthWrapper>
);

export default PrivacyPage;
