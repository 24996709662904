const ITEM_KEY_LOGIN_EXPIRATION = "loginExpiration";

export const setLoginExpiration = (expiration?: number | null) => {
  if (expiration) {
    const expirationMs = expiration * 1000;
    localStorage.setItem(ITEM_KEY_LOGIN_EXPIRATION, expirationMs.toString());
  } else {
    localStorage.removeItem(ITEM_KEY_LOGIN_EXPIRATION);
  }
};

export const getLoginExpiration = (): number | null => {
  const expiration = localStorage.getItem(ITEM_KEY_LOGIN_EXPIRATION) || "";
  return parseInt(expiration) || null;
};