// material-ui
import { useTheme } from '@mui/material/styles';

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoIconDark from 'assets/images/logo-icon-dark.svg';
 * import logoIcon from 'assets/images/logo-icon.svg';
 *
 */

// ==============================|| LOGO ICON SVG ||============================== //

const LogoIcon = () => {
  const theme = useTheme();
  const color = theme.palette.mode === 'dark' ? 'white' : theme.palette.primary.main
  return (
    /**
     * if you want to use image instead of svg uncomment following, and comment out <svg> element.
     *
     * <img src={theme.palette.mode === 'dark' ? logoIconDark : logoIcon} alt="Mantis" width="100" />
     *
     */

     <svg
     width="75"
     xmlns="http://www.w3.org/2000/svg"
     fill={color}
     viewBox="0 0 650 550"
   >
     <circle cx="328" cy="384.92" r="33.86" />
     <polygon points="399.73 202.64 399.73 418.84 467.46 418.84 467.46 134.93 399.73 202.64" />
     <path d="M340.37,135.36h-205v67.83H303l-167.48,168,48,47.63L399.73,202.64V200C399.73,165,375.36,135.36,340.37,135.36Z" />
   </svg>
  );
};

export default LogoIcon;
