// material-ui
import { Box } from "@mui/material";

// project import
import Profile from "./Profile";

// ==============================|| HEADER - CONTENT ||============================== //

const HeaderContent = () => {
  // const { i18n } = useConfig();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  // const localization = useMemo(() => <Localization />, [i18n]);

  return (
    <>
      <Box width="100%" />
      {/*{ localization }*/}
      <Profile />
    </>
  );
};

export default HeaderContent;
