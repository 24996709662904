import { JcaActionType } from "domain/jca/jca-model";
import { JcaRepository } from "domain/jca/jca-repository";
import {
  mapJcaResponse,
  mapJcaActionResultResponse,
  mapJcaActionToString,
  mapJcaDownloadResponse,
} from "./jca-mapper";
import { JcaService } from "./jca-service";

const JcaRepositoryImpl = (service: JcaService): JcaRepository => ({
  fetchJcas: async () => {
    const response = await service.fetchJcas();
    return response.map((element: any) => mapJcaResponse(element));
  },

  performAction: async (
    action: JcaActionType,
    username: string,
    message: string,
    document?: File
  ) => {
    const response = await service.performAction(
      mapJcaActionToString(action),
      username,
      message,
      document
    );
    return mapJcaActionResultResponse(response);
  },

  downloadJca: async (username: string) => {
    const response = await service.downloadJca(username);
    return mapJcaDownloadResponse(response);
  },
});

export default JcaRepositoryImpl;
