export type Jca = {
  username: string;
  name: string;
  email: string;
  address: string;
  mediaOffers?: string;
  status: JcaStatus;
  message?: string;
  sentBy?: string;
};

export type JcaActionResult = {
  newStatus: JcaStatus;
};

export type JcaDownload = {
  url: string;
};

export enum JcaActionType {
  SendOffer = "SendOffer",
  AcceptOffer = "AcceptOffer",
  RejectOffer = "RejectOffer",
  MakeCounterOffer = "MakeCounterOffer",
  CancelOffer = "CancelOffer",
  DownloadJca = "DownloadJca",
  MarkAsNotRelevant = "MarkAsNotRelevant",
  Reopen = "Reopen",
  Amend = "Amend",
}

export enum JcaStatus {
  OfferReceived = "1.OfferReceived",
  Rejected = "2.Rejected",
  NotStarted = "3.NotStarted",
  OfferSent = "4.OfferSent",
  NotRelevant = "5.NotRelevant",
  Completed = "6.Completed",
  Unknown = "7.Unknown",
}
