import { createContext, useContext } from "react";
import JcaServiceImpl from "data/jca/jca-service-impl";
import JcaRepositoryImpl from "data/jca/jca-repository-impl";
import { JcaRepository } from "domain/jca/jca-repository";
import useAuth from "hooks/useAuth";
import AxiosServices from "utils/axios";

type InjectionGraph = {
  jcaRepository: JcaRepository;
};

export const InjectionContext = createContext<InjectionGraph | undefined>(
  undefined
);

type ProviderProps = { children: JSX.Element };

export const InjectionProvider = ({ children }: ProviderProps) => {
  const auth = useAuth();

  // JCA
  const axios = AxiosServices(auth)
  const jcaService = JcaServiceImpl(axios);
  const jcaRepository = JcaRepositoryImpl(jcaService);
  const injectionGraph: InjectionGraph = {
    jcaRepository: jcaRepository,
  };

  return (
    <InjectionContext.Provider value={injectionGraph}>
      {children}
    </InjectionContext.Provider>
  );
};

export const useInjectionContext = () => {
  const context = useContext(InjectionContext);
  if (!context) {
    throw new Error("InjectionProvider not provided, use <InjectionProvider>.");
  }
  return context;
};
