// project import
import { NavItemType } from "types/menu";
import { FormattedMessage } from "react-intl";
import { UnorderedListOutlined } from "@ant-design/icons";

// ==============================|| MENU ITEMS ||============================== //

const getMenuItems = (
  userRole: string | undefined
): { items: NavItemType[] } => {

  let listTitleId;
  switch (userRole) {
    case "vendor":
      listTitleId = "jca-list-page-title-publisher";
      break;
    case "publisher":
    default:
      listTitleId = "jca-list-page-title-vendor";
      break;
  }
  
  return {
    items: [
      {
        id: "other",
        title: "",
        type: "group",
        children: [
          {
            id: "jca-list-page",
            title: <FormattedMessage id={listTitleId} />,
            type: "item",
            url: "/jca-list",
            icon: UnorderedListOutlined,
          },
        ],
      },
    ],
  };
};

export default getMenuItems;
