import { lazy } from "react";

// project import
import Loadable from "components/Loadable";
import CommonLayout from "layout/CommonLayout";
import PrivacyPage from "pages/extra-pages/privacy-page";

// render - sample page

const TermsPage = Loadable(lazy(() => import("pages/extra-pages/terms-page")));
const ImprintPage = Loadable(
  lazy(() => import("pages/extra-pages/imprint-page"))
);

// ==============================|| MAIN ROUTING ||============================== //

const CommonRoutes = {
  path: "/",
  children: [
    {
      path: "/",
      element: <CommonLayout />,
      children: [
        {
          path: "terms",
          element: <TermsPage />,
        },
        {
          path: "imprint",
          element: <ImprintPage />,
        },
        {
          path: "privacy",
          element: <PrivacyPage />,
        },
      ],
    },
  ],
};

export default CommonRoutes;
