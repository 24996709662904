import React, { createContext, useEffect, useReducer } from "react";

// third-party
import {
  CognitoUser,
  CognitoUserPool,
  CognitoUserSession,
  CognitoUserAttribute,
  AuthenticationDetails,
} from "amazon-cognito-identity-js";

// action - state management
import { LOGIN, LOGOUT } from "store/reducers/actions";
import authReducer from "store/reducers/auth";

// project imports
import Loader from "components/Loader";
import { AWSCognitoContextType, InitialLoginContextProps } from "types/auth";
import { getLoginExpiration, setLoginExpiration } from "utils/auth";

// constant
const initialState: InitialLoginContextProps = {
  isLoggedIn: false,
  isInitialized: false,
  user: null,
};

export const userPool = new CognitoUserPool({
  endpoint: process.env.REACT_APP_COGNITO_ENDPOINT || "",
  UserPoolId: process.env.REACT_APP_COGNITO_POOL_ID || "",
  ClientId: process.env.REACT_APP_COGNITO_APP_CLIENT || "",
});

// ==============================|| AWS Cognito CONTEXT & PROVIDER ||============================== //

const AWSCognitoContext = createContext<AWSCognitoContextType | null>(null);

export const AWSCognitoProvider = ({
  children,
}: {
  children: React.ReactElement;
}) => {
  const [state, dispatch] = useReducer(authReducer, initialState);

  const handleLogin = (session: CognitoUserSession) => {
    const idTokenPayload = session.getIdToken().payload;

    dispatch({
      type: LOGIN,
      payload: {
        isLoggedIn: true,
        user: {
          username: idTokenPayload["cognito:username"],
          email: idTokenPayload.email,
          name: idTokenPayload.name,
          role: idTokenPayload["cognito:groups"]?.first,
        },
      },
    });
  };

  const handleLogout = (err: any = null) => {
    if (err) console.log(err);
    setLoginExpiration(null);
    userPool.getCurrentUser()?.signOut();
    dispatch({ type: LOGOUT });
  };

  const getSession = async (): Promise<CognitoUserSession> => {
    return new Promise((success, rej) => {
      const currentUser = userPool.getCurrentUser();
      if (currentUser) {
        currentUser.getSession(
          (err: Error | null, session: CognitoUserSession | null) => {
            if (session && !err) {
              success(session);
            } else {
              rej(err);
            }
          }
        );
      } else {
        rej(new Error("No user is currently logged-in."));
      }
    });
  };

  useEffect(() => {
    const refreshSession = async () => {
      const expiration = getLoginExpiration();
      const now = new Date().getTime();
      if (expiration && expiration < now) {
        handleLogout();
        return;
      }
      try {
        handleLogin(await getSession());
      } catch (err) {
        handleLogout(err);
      }
    };
    refreshSession();
  }, []);

  const login = (email: string, password: string, keepLogin: boolean) =>
    new Promise<void>((success, rej) => {
      const usr = new CognitoUser({
        Username: email,
        Pool: userPool,
      });

      const authData = new AuthenticationDetails({
        Username: email,
        Password: password,
      });

      usr.authenticateUser(authData, {
        onSuccess: (session: CognitoUserSession) => {
          if (!keepLogin) {
            setLoginExpiration(session.getAccessToken().getExpiration());
          }
          handleLogin(session);
          success();
        },
        onFailure: (error) => {
          rej(error);
        },
      });
    });

  const register = (
    email: string,
    password: string,
    companyName: string,
    contactFirstName: string,
    contactLastName: string,
    contactJobTitle: string,
    managedDomains: string,
    addressStreet: string,
    addressNumber: string,
    addressPostcode: string,
    addressCity: string,
    addressCountry: string,
    originalUserName: string,
    originalUserType: string
  ) =>
    new Promise((success, rej) => {
      const address = `${addressStreet} ${addressNumber}, ${addressPostcode} ${addressCity}, ${addressCountry}`;
      userPool.signUp(
        email,
        password,
        [
          new CognitoUserAttribute({ Name: "email", Value: email }),
          new CognitoUserAttribute({ Name: "name", Value: companyName }),
          new CognitoUserAttribute({
            Name: "given_name",
            Value: contactFirstName,
          }),
          new CognitoUserAttribute({
            Name: "family_name",
            Value: contactLastName,
          }),
          new CognitoUserAttribute({ Name: "address", Value: address }),
          new CognitoUserAttribute({ Name: "website", Value: managedDomains }),
          new CognitoUserAttribute({
            Name: "custom:jobTitle",
            Value: contactJobTitle,
          }),
          new CognitoUserAttribute({
            Name: "custom:originalUsername",
            Value: originalUserName,
          }),
          new CognitoUserAttribute({
            Name: "custom:originalUserType",
            Value: originalUserType,
          }),
        ],
        [],
        async (err, result) => {
          if (err) {
            rej(err);
            return;
          }
          success(result);
        }
      );
    });

  const logout = () => {
    handleLogout();
  };

  const confirm = (username: string, code: string) => {
    return new Promise((success, rej) => {
      const user = new CognitoUser({
        Username: username,
        Pool: userPool,
      });

      user.confirmRegistration(code, false, (err, result) => {
        if (err) {
          rej(err);
        } else {
          success(result);
        }
      });
    });
  };

  const resetPassword = (
    username: string,
    newPassword: string,
    code: string
  ) => {
    return new Promise((success, rej) => {
      const user = new CognitoUser({
        Username: username,
        Pool: userPool,
      });

      user.confirmPassword(code, newPassword, {
        onSuccess: (result) => {
          success(result);
        },
        onFailure: (err) => {
          rej(err);
        },
      });
    });
  };

  const requestPasswordReset = async (email: string) => {};
  const updateProfile = async () => {};

  if (state.isInitialized !== undefined && !state.isInitialized) {
    return <Loader />;
  }

  return (
    <AWSCognitoContext.Provider
      value={{
        ...state,
        login,
        logout,
        register,
        confirm,
        resetPassword,
        requestPasswordReset,
        updateProfile,
        getSession,
      }}
    >
      {children}
    </AWSCognitoContext.Provider>
  );
};

export default AWSCognitoContext;
