// material-ui
import { useTheme } from "@mui/material/styles";

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

// ==============================|| LOGO SVG ||============================== //

const LogoMain = ({ reverse, ...others }: { reverse?: boolean }) => {
  const theme = useTheme();
  const color = theme.palette.mode === 'dark' ? 'white' : theme.palette.primary.main
  return (
    /**
     * if you want to use image instead of svg uncomment following, and comment out <svg> element.
     *
     * <img src={theme.palette.mode === 'dark' ? logoDark : logo} alt="Mantis" width="100" />
     *
     */
    <>
      <svg
        width="200"
        xmlns="http://www.w3.org/2000/svg"
        fill={color}
        viewBox="0 0 1545.45 554.17"
      >
        <circle cx="328" cy="384.92" r="33.86" />
        <polygon points="399.73 202.64 399.73 418.84 467.46 418.84 467.46 134.93 399.73 202.64" />
        <path d="M340.37,135.36h-205v67.83H303l-167.48,168,48,47.63L399.73,202.64V200C399.73,165,375.36,135.36,340.37,135.36Z" />
        <path d="M529,194.14l7.23-13.9c7.62,6.47,16.7,9.36,24,9.36,6.27,0,10-2.12,10-5.69,0-12.06-38.79-1.45-38.79-27.69,0-13.9,12.74-21.62,29.43-21.62,9.46,0,20.08,2.9,27.22,7.53l-7.24,13.8a37.73,37.73,0,0,0-21.23-6.85c-6.18,0-10.42,1.93-10.42,5.5,0,12.15,38.69,1.83,38.69,27.5,0,13.6-12.06,22-28.17,22C549.14,204.08,537.47,200.51,529,194.14Z" />
        <path d="M632.41,189a19.76,19.76,0,0,0,16.41-7.81l11.29,11.29c-5.6,7.14-15.54,11.58-28.08,11.58-20.84,0-36.38-14.28-36.38-34.65,0-20,14.47-34.83,34.93-34.83,22.39,0,36.57,16.79,32.33,41H613.6C616,183.72,623.25,189,632.41,189Zm14.29-25.86c-1.16-9.65-7.43-14.66-16-14.66-9.36,0-15.54,5.79-17.47,14.66Z" />
        <path d="M736,135.57l-36.18,68.8h-.2l-36.37-68.8h18.91l17.46,34.35L717,135.57Z" />
        <path d="M772.14,189a19.75,19.75,0,0,0,16.4-7.81l11.29,11.29c-5.6,7.14-15.53,11.58-28.08,11.58-20.84,0-36.38-14.28-36.38-34.65,0-20,14.48-34.83,34.93-34.83,22.39,0,36.58,16.79,32.33,41H753.32C755.73,183.72,763,189,772.14,189Zm14.28-25.86c-1.16-9.65-7.43-14.66-16-14.66-9.36,0-15.54,5.79-17.47,14.66Z" />
        <path d="M876.74,165.86v37.25H860.52V165.48c0-10.42-4.44-14.86-14.57-14.86H830.9v52.49H814.59V135.57h31C866.6,135.57,876.74,145.41,876.74,165.86Z" />
        <path d="M889.08,194a9.75,9.75,0,1,1,19.5,0,9.75,9.75,0,0,1-19.5,0Z" />
        <path d="M986.74,169.43c0,20.37-15.06,34.65-36.09,34.65-20.85,0-35.9-14.28-35.9-34.65s15-34.83,35.9-34.83C971.68,134.6,986.74,149,986.74,169.43Zm-16.89-.09c0-11.39-8-19.2-19.11-19.2s-19,7.81-19,19.2,7.92,19.2,19,19.2S969.85,180.72,969.85,169.34Z" />
        <path d="M1060.75,135.57V205h-.2l-46.31-38.11v36.18H997.93V133.64h.19l46.32,37.43v-35.5Z" />
        <path d="M1108.7,189a19.75,19.75,0,0,0,16.4-7.81l11.29,11.29c-5.59,7.14-15.53,11.58-28.08,11.58-20.84,0-36.37-14.28-36.37-34.65,0-20,14.47-34.83,34.93-34.83,22.38,0,36.57,16.79,32.32,41h-49.31C1092.3,183.72,1099.53,189,1108.7,189ZM1123,163.16c-1.16-9.65-7.43-14.66-16-14.66-9.36,0-15.53,5.79-17.46,14.66Z" />
        <path d="M544.9,252.36v20.16h37.73v9H544.9V302h39.85v9h-49.5V243.38h49.5v9Z" />
        <path d="M660.5,243.38v68.8h-.2l-48.73-48.05v46.8h-9.65v-68.8h.2l48.73,47.18V243.38Z" />
        <path d="M733.44,252.45H707.68v58.48H698V252.45H672.17v-9.07h61.27Z" />
        <path d="M754.77,252.36v20.16H792.5v9H754.77V302h39.85v9h-49.5V243.38h49.5v9Z" />
        <path d="M837.56,285.93H821.44v25h-9.65V243.38h29.53c14.57,0,24.12,8.11,24.12,20.65,0,11.48-7,19.59-18.33,21.52a29.48,29.48,0,0,1,5.11,5.31L867,310.93H855Zm-16.12-9.07h20.07c8.78,0,14.09-4.82,14.09-12.73,0-7.34-5.31-11.77-14.09-11.77H821.44Z" />
        <path d="M931.54,252.45H905.78v58.48h-9.65V252.45H870.27v-9.07h61.27Z" />
        <path d="M979.11,293.17H945.53l-7.33,17.76H927.87l28.47-67.55H968.4l28.37,67.55H986.45Zm-3.57-8.88-13.12-31.84L949.2,284.29Z" />
        <path d="M1008.35,243.38H1018v67.55h-9.65Z" />
        <path d="M1095.58,243.38v68.8h-.2l-48.73-48.05v46.8H1037v-68.8h.2l48.73,47.18V243.38Z" />
        <path d="M1192.84,243.38v67.55h-9.65V254.86l-29.43,57h-.19l-29.43-57v56.07h-9.65l.09-67.55h14.19l24.89,49.12,24.9-49.12Z" />
        <path d="M1221.5,252.36v20.16h37.73v9H1221.5V302h39.85v9h-49.5V243.38h49.5v9Z" />
        <path d="M1337.1,243.38v68.8h-.2l-48.72-48.05v46.8h-9.65v-68.8h.19l48.73,47.18V243.38Z" />
        <path d="M1410.05,252.45h-25.77v58.48h-9.65V252.45h-25.86v-9.07h61.28Z" />
        <path d="M565.84,419.71c-20.17,0-35-14.48-35-34.74s15.05-34.64,35.12-34.64c10.62,0,19.69,4.05,25.77,10.71l-7.14,7a23.61,23.61,0,0,0-18.63-8.49c-14.09,0-25.09,10.71-25.09,25.38,0,14.86,10.91,25.47,25,25.47,12.64,0,20.65-8.68,22.38-20.07H565.93v-9h32.62C600.48,402.92,587.06,419.71,565.84,419.71Z" />
        <path d="M639,393.75H622.86v25h-9.65V351.2h29.53c14.57,0,24.12,8.1,24.12,20.65,0,11.48-7,19.58-18.33,21.51a29.82,29.82,0,0,1,5.11,5.31l14.77,20.07h-12Zm-16.12-9.07h20.07c8.78,0,14.09-4.83,14.09-12.74,0-7.33-5.31-11.77-14.09-11.77H622.86Z" />
        <path d="M747.34,385.06c0,20.27-15.05,34.65-35.22,34.65S677,405.33,677,385.06s15.06-34.83,35.13-34.83S747.34,364.71,747.34,385.06Zm-10,0c0-14.95-11-25.56-25.18-25.56S687,370.11,687,385.06c0,14.77,11,25.38,25.09,25.38S737.3,399.83,737.3,385.06Z" />
        <path d="M817.2,351.2v43.32c0,16.31-11.48,25.19-28.18,25.19s-28.17-8.88-28.17-25.19V351.2h9.65v42.26c0,11.1,7.52,17,18.52,17s18.53-5.88,18.53-17V351.2Z" />
        <path d="M888,372.71c0,12.84-9.45,21.52-24.22,21.52h-19v24.51h-9.65V351.2H863.8C878.57,351.2,888,359.88,888,372.71Zm-10,0c0-7.91-5.31-12.54-14.38-12.54H844.8v25.09h18.81C872.68,385.26,878,380.63,878,372.71Z" />
      </svg>
    </>
  );
};

export default LogoMain;
