import { AxiosInstance } from "axios";
import {
  JcaActionResultResponse,
  JcaDownloadResponse,
  JcaResponse,
} from "./jca-response-model";
import { JcaService } from "./jca-service";

const JcaServiceImpl = (axios: AxiosInstance): JcaService => ({
  fetchJcas: async (): Promise<JcaResponse[]> => {
    const { data } = await axios.get<JcaResponse[]>("/jcas");
    return data;
  },

  performAction: async (
    action: string,
    username: string,
    message: string,
    document?: File
  ): Promise<JcaActionResultResponse> => {
    const formData = new FormData();
    formData.append("action", action);
    formData.append("target", username);
    formData.append("message", message);
    if (document) {
      formData.append("document", document);
    }
    const { data } = await axios.post<JcaActionResultResponse>(
      "/perform_action",
      formData
    );
    return data;
  },

  downloadJca: async (username: string): Promise<JcaDownloadResponse> => {
    const { data } = await axios.get<JcaDownloadResponse>("/download_jca", {
      params: {
        target: username,
      },
    });
    return data;
  },
});

export default JcaServiceImpl;
