// material-ui
import { Theme } from "@mui/material/styles";
import {
  useMediaQuery,
  Container,
  Link,
  Typography,
  Stack,
} from "@mui/material";

// ==============================|| FOOTER - AUTHENTICATION ||============================== //

const AuthFooter = () => {
  const matchDownSM = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  return (
    <Container maxWidth="xl">
      <Stack
        direction={matchDownSM ? "column" : "row"}
        justifyContent={matchDownSM ? "center" : "space-between"}
        spacing={2}
        textAlign={matchDownSM ? "center" : "inherit"}
      >
        <Typography variant="subtitle2" color="secondary" component="span">
          <Typography
            component={Link}
            variant="subtitle2"
            href="#mantis-privacy"
            target="_blank"
            underline="hover"
          ></Typography>
        </Typography>

        <Stack
          direction={matchDownSM ? "column" : "row"}
          spacing={matchDownSM ? 1 : 3}
          textAlign={matchDownSM ? "center" : "inherit"}
        >
          <Typography
            variant="subtitle2"
            color="secondary"
            component={Link}
            href="/terms"
            target="_blank"
            underline="hover"
          >
            Terms and Conditions
          </Typography>
          <Typography
            variant="subtitle2"
            color="secondary"
            component={Link}
            href="/imprint"
            target="_blank"
            underline="hover"
          >
            Imprint
          </Typography>
          <Typography
            variant="subtitle2"
            color="secondary"
            component={Link}
            href="/privacy"
            target="_blank"
            underline="hover"
          >
            Privacy Policy
          </Typography>
        </Stack>
      </Stack>
    </Container>
  );
};

export default AuthFooter;
